<script>
  import { dateOptions, inform, t } from '@dabble/app';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiCheckboxBlankCircleOutline, mdiCheckboxMarkedCircle, mdiLoading } from '@mdi/js';
  import { addMonths, format } from 'date-fns';
  import { createEventDispatcher } from 'svelte';
  import { billing } from '../billing';

  export let fromCancelSurvey = false;

  const dispatch = createEventDispatcher();

  $: selectedOption = '';
  $: saving = false;
  $: failed = false;

  const pauseOptions = [1, 2, 3];

  function setSelectedOption(key) {
    selectedOption = key;
  }

  async function pauseSubscription() {
    if (typeof selectedOption === 'number') {
      saving = true;
      const length = selectedOption;
      const resumeDate = addMonths(new Date(), length);
      const resumeStamp = resumeDate.getTime() / 1000;
      try {
        await billing.pauseSubscription(resumeStamp);
      } catch (e) {
        console.error(e);
        inform('danger', e.message);
        failed = true;
      }

      saving = false;

      if (!failed) {
        const date = resumeDate.getTime();
        inform('success', $t('billing_paused_subscription', { resumeAt: format(date, 'PP', dateOptions) }));
        dispatch('pauseSubscription', { resumeDate: date, length });
      }
    }
  }
</script>

<div class="pause-subscription">
  <div class="pause-options">
    {#each pauseOptions as option}
      <button class="option" on:click={() => setSelectedOption(option)} class:checked={selectedOption === option}>
        <div class="option-icon">
          {#if selectedOption === option}
            <Icon path={mdiCheckboxMarkedCircle} />
          {:else}
            <Icon path={mdiCheckboxBlankCircleOutline} />
          {/if}
        </div>
        <span class="option-label">{$t(`pause_subscription_option_${option}`)}</span>
      </button>
    {/each}
    {#if fromCancelSurvey}
      <button
        class="option cancel"
        on:click={() => setSelectedOption('cancel')}
        class:checked={selectedOption === 'cancel'}
      >
        <div class="option-icon">
          {#if selectedOption === 'cancel'}
            <Icon path={mdiCheckboxMarkedCircle} />
          {:else}
            <Icon path={mdiCheckboxBlankCircleOutline} />
          {/if}
        </div>
        <span class="option-label">{$t('pause_subscription_cancel_instead')}</span>
      </button>
    {/if}
  </div>

  <div class="footer-buttons">
    <button class="btn primary" on:click={() => dispatch('backToBilling')}>{$t('exit_survey_never_mind')}</button>
    {#if selectedOption === 'cancel' || (fromCancelSurvey && !selectedOption)}
      <button
        class="btn danger"
        on:click={() => dispatch('cancel')}
        disabled={saving || (!selectedOption && !fromCancelSurvey)}
        >{$t('pause_subscription_confirm_cancellation')}</button
      >
    {:else}
      <button
        class="btn primary"
        on:click={pauseSubscription}
        disabled={saving || (!selectedOption && !fromCancelSurvey)}
      >
        {#if saving}
          <Icon path={mdiLoading} spin />
        {/if}
        {$t('pause_subscription_confirm')}
      </button>
    {/if}
  </div>
</div>

<style>
  .pause-options {
    margin-bottom: 1rem;
  }
  .footer-buttons {
    display: flex;
    justify-content: flex-end;
  }
  .footer-buttons .btn {
    margin-left: 0.5rem;
  }
  .option {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin-bottom: 0.5rem;
    padding: 0;
    border: none;
    background: none;
  }
  .option.checked .option-icon {
    color: var(--dabble-blue);
  }
  .option-icon {
    font-size: 24px;
    display: flex;
    align-items: center;
  }
  .option-icon {
    margin-right: 7px;
  }
</style>
