import { alert, projectStore, readonly, t } from '@dabble/app';
import { Change } from '@dabble/data/collective/changes';
import { ProjectData } from '@dabble/data/stores/project';
import { Project } from '@dabble/data/types';
import logging from '@dabble/util/log';
import { getGridFromPlot, updateGrid } from './model/grid-builder';
import { fixPlotLines } from './plot-line-fixer';

const log = logging.tagColor('Grid', '#F2385A');

// Check if the plot grid is accurate when loading a project
let project: Project;
let change: Change;

projectStore.subscribe(data => {
  if (data.project && (!project || project.id !== data.project.id)) {
    isGridBroken(data, true);
  }
  project = data.project;
});

projectStore.subscribe(data => {
  if (data.project && data.change && data.change != change) {
    if (!isGridBroken(data)) {
      console.log('All grids are accurate');
    } else {
      console.log('Grids were broken and fixGrids was called');
    }
  }
  change = data.change;
});

export const fixGrids = () => {
  const data = projectStore.get();
  Object.values(data.docs).forEach(async doc => {
    if (doc.type !== 'novel_plot') return;
    const hasLink = !!Object.keys(data.project.links).find(key => key.startsWith(`${doc.id}:plot:`));
    let grid = getGridFromPlot(data.project, doc.id);
    grid = updateGrid(data.project, doc.grid, grid, hasLink);
    if (doc.grid !== grid) {
      log('Fixing:', doc.id);
      await projectStore.updateDoc(doc.id, { grid });
    }
  });
  fixPlotLines();
};

(window as any).fixGrids = fixGrids;

export function isGridBroken(data: ProjectData, verbose?: boolean) {
  let broke = false;
  Object.values(data.docs).forEach(async doc => {
    if (doc.type !== 'novel_plot') return;
    const hasLink = !!Object.keys(data.project.links).find(key => key.startsWith(`${doc.id}:plot:`));
    let grid = getGridFromPlot(data.project, doc.id);
    grid = updateGrid(data.project, doc.grid, grid, hasLink, true);
    if (doc.grid !== grid) {
      if (!doc.grid) {
        // just add it if it doesn't exist
        projectStore.updateDoc(doc.id, { grid });
        return;
      }
      broke = true;
      if (verbose) log('Grid needs fixing:', doc.id);
      if (!readonly.get()) {
        fixGrids();
        alert(t.get()('novel_plot_incorrect_title'), t.get()('novel_plot_incorrect_on_load'));
      }
    }
  });
  return broke;
}
