import {
  collective,
  dbStore,
  finishedSyncing,
  fromRemote,
  globalData,
  loadedProjects,
  plugins,
  preferences,
  projectMetas,
  userProjects,
  version,
} from '@dabble/app';
import { Where } from '@dabble/data/connection';
import { getDateString } from '@dabble/data/date';
import { userProjectSort } from '@dabble/data/stores/user-projects';
import { ProjectMeta } from '@dabble/data/types';
import logging from '@dabble/util/log';
import * as Sentry from '@sentry/browser';
import { subDays } from 'date-fns';
import { connection } from './active-connection';
import { createSyncProcess } from './process';
import { setProjectFunctions } from './steps/available-migrations';
import syncLastModifiedStep from './steps/last-modified';
import migrationsStep from './steps/migrations';
import checkMinimumVersionStep from './steps/min-version';
import syncProjectsStep from './steps/projects';
import updateGlobalTemplates from './steps/templates';
import { syncing } from './syncing';
import { SyncProcess, SyncStep } from './types';

const log = logging.tagColor('Sync', '#36B1BF');
let process: SyncProcess;

const fixMetas: SyncStep = {
  name: 'fixMetas',
  async sync() {
    const db = dbStore.get();
    const firstSync = !(await db.stores.other.get('projects_since'));
    const metas = projectMetas.get();
    const uncommitted = Object.values(metas).filter(meta => !meta.committed || !meta.roles);
    const results = await Promise.all(
      uncommitted.map(async meta => {
        try {
          return connection.getDoc('projects', { id: meta.id });
          // eslint-disable-next-line no-empty
        } catch (err) {}
      })
    );

    const updated: ProjectMeta[] = [];

    for (let i = 0; i < uncommitted.length; i++) {
      const local = uncommitted[i];
      const remote = results[i];
      if (local && remote) {
        if (!remote.committed) {
          remote.committed = await connection.putDoc('projects', remote);
        }
        local.committed = remote.committed;
        updated.push(local);
      }
    }

    if (updated.length) {
      await db.stores.project_metas.putAll(updated);
    }

    if (!firstSync) {
      await Promise.all(
        userProjects.get().map(async p => {
          if (!metas[p.projectId]) {
            try {
              const meta = (await connection.getDoc('projects', { id: p.projectId })) as ProjectMeta;
              updated.push(meta);
              projectMetas.update(meta.id, meta);
              // eslint-disable-next-line no-empty
            } catch (err) {}
          }
        })
      );
    }
  },

  start() {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  },
};

export async function startSyncing() {
  if (sessionStorage.dontSync) return;
  let initialSessionSync = false;
  if (!process) {
    initialSessionSync = true;
    process = createProcess();
    process.onError(async err => {
      syncing.error(err);
      Sentry.captureException(err, {
        tags: {
          sync: 'onError',
        },
      });

      // connection.disconnect();
      // await new Promise(r => setTimeout(r, 1000));
      // connection.connect();
    });
  }

  if (!process.isSyncing()) {
    log('Starting sync');
    syncing.syncing(true);
    await process.start();
    await finishedSyncing(initialSessionSync);
    log('Synced');
    syncing.syncing(false);
  }
}

export function stopSyncing() {
  process.stop();
  log('Stopped syncing');
}

export function isSyncing() {
  return process && process.isSyncing();
}

function createProcess() {
  const db = dbStore.get();
  const lastModifiedData = { db, connection, fromRemote, syncing };
  const projectsData = {
    ...lastModifiedData,
    userProjects,
    projectMetas,
    loadedProjects,
    currentUser: plugins.stores.currentUser,
    collective,
  };
  const date = getDateString(subDays(new Date(), 30));
  const last30Days: Where = ['date', '>=', date];

  // Bit of a hack, but um, this is dependency injection into the available migrations, so it is for a good cause.
  const projectsStep = syncProjectsStep(projectsData);
  setProjectFunctions(projectsStep.startSyncingProject, projectsStep.stopSyncingProject);

  const steps: Array<SyncStep | SyncStep[]> = [
    syncLastModifiedStep('global', lastModifiedData),
    checkMinimumVersionStep({ version, db, connection, globalData }),
    migrationsStep({ version, db, connection, syncing }),
    [
      syncLastModifiedStep('user_meta', lastModifiedData),
      syncLastModifiedStep('user_goals', lastModifiedData),
      syncLastModifiedStep('user_stats_by_date', lastModifiedData, { firstFilter: [last30Days] }),
      syncLastModifiedStep('user_projects', lastModifiedData, { sort: userProjectSort }),
    ],
    updateGlobalTemplates({ userProjects, globalData, preferences }),
    fixMetas,
    syncLastModifiedStep('projects', lastModifiedData, {
      storeName: 'project_metas',
      getIds: () => userProjects.get().map(p => p.projectId),
      merge: true,
      forceSync: store => store.where('committed').equals(0).getAll(),
      onRemoteSave: async (project: ProjectMeta, committed, localRecords) => {
        const isNew = !project.committed;
        if (isNew) {
          project = (await connection.getDoc('projects', { id: project.id })) as ProjectMeta;
        }
        project.committed = committed;
        localRecords[project.id] = project;
        db.stores.project_metas.put(project);
        if (isNew) db.dispatchChange(db.stores.project_metas, project, project.id, 'remote');
      },
      onRemoteLoad: (project: ProjectMeta) => {
        // const isFullySynced = Object.keys(project.roles).length > 0;
        // const hasPermission = project.isTemplate || project.roles[projectsData.currentUser.get().uid];
        // if (isFullySynced && !hasPermission) {
        //   deleteProject(project.id, true);
        //   return false;
        // }
        if (!project.committed) {
          project.committed = project.modified;
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onRemoteForbidden: () => {},
    }),
    projectsStep,
  ];
  return createSyncProcess(steps);
}
