import { Change } from '@dabble/data/collective/changes';
import { Doc, EditorOptions, Project } from '@dabble/data/types';
import { reportError } from '@dabble/error-reporting';
import { mentionsModule } from '@dabble/toolkit/editor-modules/mentions';
import { onNextChange } from '@dabble/toolkit/helpers';
import debounce from 'lodash/debounce';
import {
  Delta,
  Editor,
  EditorChangeEvent,
  ModuleInitializer,
  ModuleInitializers,
  Source,
  TextDocument,
  TypesetTypes,
  format,
  h,
  line,
  placeholder,
  smartEntry,
  smartQuotes,
} from 'typewriter-editor';
import { HistoryModule, UndoStack, transformHistoryStack, undoStack } from 'typewriter-editor/lib/modules/history';
import { textReplacements } from 'typewriter-editor/lib/modules/smartEntry';
import { dl } from 'typewriter-editor/lib/typesetting';
import findReplace from '../toolkit/editor-modules/find-replace';
import links from '../toolkit/editor-modules/links';
import navigation from '../toolkit/editor-modules/navigation';
import noteLinking from '../toolkit/editor-modules/note-linking';
import pasteHandling from '../toolkit/editor-modules/paste-handling';
import spaces from '../toolkit/editor-modules/spaces';
import { isProduction } from '../version';
import { ChangeProjectEvent, Collective, ReceiveChangesEvent } from './collective/collective';
import { ChangeSignalEvent, PENDING, ProjectData, ProjectStore, SAVED } from './stores/project';
import { ProjectMetasStore } from './stores/project-metas';
import { SettingsStore } from './stores/settings';
import { Readable, Unsubscriber, writable } from './stores/store';

type DeltaCallback = (delta: Delta, changeId: string) => void;

export interface EditorElement extends Element {
  editor?: Editor;
}

export interface Editable {
  key: string;
  editor: Editor;
  empty: Readable<boolean>;
  save(): void;
  destroy(): void;
}

export interface Editables {
  getEditable(docId: string, field: string, options?: EditorOptions): Editable;
}

export interface EditableData {
  projectStore: ProjectStore;
  projectMetas: ProjectMetasStore;
  settings: SettingsStore;
  collective: Collective;
}

textReplacements.push([/<<$/g, () => '«'], [/>>$/g, () => '»']);

const blurModule: ModuleInitializer = editor => ({
  shortcuts: {
    Escape: 'blur',
  },
  commands: {
    blur: () => editor.root.ownerDocument.getSelection().removeAllRanges(),
  },
});

// Happens once
export function createEditables({ projectStore, projectMetas, settings, collective }: EditableData) {
  const editors: Record<string, Set<Editor>> = {};
  const histories: Record<string, UndoStack> = {};
  const editorKeys: WeakMap<Editor, string> = new Map();

  // These are for titles so they cannot change from e.g. h1 to h2
  for (let i = 1; i <= 4; i++) {
    const tag = `h${i}`;
    line({ name: tag, selector: tag, render: (att, children) => h(tag, null, children) });
  }

  line({
    name: 'dlh',
    selector: '.dlh',
    nextLineAttributes: () => ({ dl: 'dt' }),
    commands: editor => () => editor.toggleLineFormat({ dlh: true }),
    render: (att, children) => h('h6', { class: 'dlh' }, children),
  });

  format({
    name: 'underline',
    selector: 'u',
    styleSelector: '[style*="text-decoration:underline"], [style*="text-decoration: underline"]',
    commands: editor => () => editor.toggleTextFormat({ underline: true }),
    shortcuts: 'Mod+U',
    render: (attributes, children) => h('u', null, children),
  });

  dl.onEmptyEnter = (editor, line) => line.attributes.dl === 'dt';

  collective.on('changeProject', onChangeProject);
  collective.on('receiveChanges', onReceiveChanges);

  function getEditable(docId: string, field: string, root: HTMLElement, options?: EditorOptions): Editable {
    let projectId: string;
    let project: Project;
    let doc: Doc;
    let key: string;
    let value: string;
    const subscriptions: Unsubscriber[] = [];
    // Updates the options object immediately
    subscriptions.push(projectStore.subscribe(onProjectUpdate));
    if (projectId === docId) {
      subscriptions.push(projectMetas.subscribe(onProjectMetaUpdate));
    }
    if (!project || !doc) throw new Error('Error finding project and doc for editable.');

    const editor = getEditor(project, doc, field, options);
    const saveFieldDebounced = debounce(save, 1000);
    const empty = writable(true);

    editor.setRoot(root);
    root.className = 'typewriter-editor';

    const history = editor.modules.history as HistoryModule;
    history.options.delay = 4000;
    history.setStack(getHistoryStack(key));

    updateEmpty();
    editor.on('change', onEditorChange);
    editor.on('error', onError);
    subscriptions.push(
      projectStore.onChange(onProjectChange),
      projectStore.onReceiveChanges(onReceiveChanges),
      projectStore.saveText(onSaveText)
    );
    window.addEventListener('beforeunload', save);
    root.addEventListener('blur', save);

    function onEditorChange({ old, doc, change, changedLines, source }: EditorChangeEvent) {
      editor.root.dispatchEvent(
        new CustomEvent('editor-change', {
          bubbles: true,
          detail: { editor, old, doc, change, changedLines, source },
        })
      );
      if (!change?.contentChanged) return;
      updateEmpty();
      if (source === Source.api) return;

      if (options.header) {
        projectStore.status.set(PENDING);
        return saveFieldDebounced();
      }

      // Add to the queue to save in 3-30 seconds
      projectStore.queueTextChange(docId, field, change.delta, source === 'grammar');
    }

    function onError(event: ErrorEvent) {
      reportError(event.error);
    }

    // This all comes from local changes. Only look at remote (other tab) to safely add
    function onProjectChange({ change, snapshot, fromEditor }: ChangeSignalEvent) {
      if (project.id !== snapshot.id || fromEditor) return;

      // Find text changes, determine if they are part of this document, and apply them.
      forEachDeltaInChange(change, mergeDelta);
    }

    // These may have rebased changes at the end which are already part of this editable
    function onReceiveChanges(event: ReceiveChangesEvent) {
      let hasRebased = false;
      forEachDeltaInChanges(event.rebased, () => (hasRebased = true));

      if (hasRebased) {
        // Let the project-update update this if it has any text changes for this field
        updateAgainstProject();
      } else {
        // Merge the changes in
        forEachDeltaInChanges(event.changes, mergeDelta);
      }
    }

    function updateAgainstProject() {
      onNextChange(projectStore, project => {
        try {
          const oldDelta = editor.doc.toDelta();
          const delta = project.docs[docId][field];
          const change = oldDelta.diff(delta);
          if (change.length()) mergeDelta(change);
        } catch (e) {
          projectStore.forceTextUpdate(key);
        }
      });
    }

    // Merges this incoming delta into the current document and transforms the queued text change for it, if any
    function mergeDelta(delta: Delta) {
      // A new change has come in that we haven't seen. Apply it.
      delta = projectStore.transformAgainstQueuedTextChanges(docId, field, delta);
      if (delta.length()) editor.update(delta, Source.api);
    }

    function forEachDeltaInChanges(changes: Change[], callback: DeltaCallback) {
      return changes.forEach(change => forEachDeltaInChange(change, callback));
    }

    // Finds deltas in this change for the docId and field for this editor and calls the callback for each one.
    function forEachDeltaInChange(change: Change, callback: DeltaCallback) {
      return change.ops.forEach(op => {
        if (op.op === '@changeText') {
          const [, , theDocId, theField] = op.path.split('/');
          if (theDocId === docId && theField === field && Array.isArray(op.value) && op.value.length) {
            callback(new Delta(op.value), change.id);
          }
        }
      });
    }

    function onSaveText(saveKey?: string) {
      if (saveKey && saveKey !== key) return;
      save();
    }

    async function save() {
      if (!doc) return;
      if (options.header) {
        projectStore.status.set(SAVED);
        const value = editor.getText();
        if (doc.type === 'novel_title_page' && projectStore.get().project) {
          const bookDoc = Object.values(projectStore.get().project.docs).find(doc => doc.type === 'novel_book');
          projectStore.updateDoc(bookDoc.id, { [field]: value });
          projectMetas.update(projectId, { [field]: value });
        }
        const oldValue = doc[field] || '';
        if (oldValue !== value) {
          if (options.onSave) options.onSave(value, oldValue, docId, field);
          else projectStore.updateDoc(doc.id, { [field]: value });
        }
      } else if (projectStore.get().project) {
        projectStore.commitQueuedTextChanges(docId, field);
      }
    }

    function updateEmpty() {
      empty.set(editor.doc.length === 1);
    }

    function onValueChange(value = '') {
      if (!options.header) return;
      if (editor && value !== editor.getText()) {
        editor.setText(value || '');
      }
    }

    function onProjectUpdate(projectData: ProjectData) {
      project = projectData.project;
      if (projectId && (!project || projectId !== project.id)) {
        destroy();
      } else if (project) {
        doc = docId === project.id ? projectMetas.get()[project.id] : project.docs[docId];
        if (!doc) {
          projectStore.cancelQueuedTextChanges(key);
          return destroy();
        }

        options = getOptions(doc, field, options);

        // Initialization
        if (!projectId) {
          projectId = project.id;
          key = projectStore.getQueueKey(docId, field);
          value = doc[field];

          // Subsequent changes
        } else {
          if (options.header && value !== doc[field]) {
            value = doc[field];
            onValueChange(value);
          }
        }
      }
    }

    function onProjectMetaUpdate() {
      if (options.header && value !== doc[field]) {
        value = doc[field];
        onValueChange(value);
      }
    }

    function destroy() {
      if (!editorKeys.has(editor)) return;
      save();
      subscriptions.forEach(unsub => unsub());
      returnEditor(editor);
      editor.off('change', onEditorChange);
      editor.off('error', onError);
      editor.root.removeEventListener('blur', save);
      window.removeEventListener('beforeunload', save);
      editor.destroy();
    }

    (window as any).editor = editor;

    return {
      key,
      editor,
      empty,
      save,
      destroy,
    };
  }

  function onChangeProject(event: ChangeProjectEvent) {
    if (event.remote || !event.fromEditor) updateHistoriesAgainstIncomingChange(event.change);
  }

  function onReceiveChanges(event: ReceiveChangesEvent) {
    event.changes.forEach(updateHistoriesAgainstIncomingChange);
  }

  function updateHistoriesAgainstIncomingChange(change: Change) {
    change.ops.forEach(op => {
      if (op.op !== '@changeText') return;
      const [, , docId, field] = op.path.split('/'); // e.g. /docs/8d3A/body
      if (!docId) return; // do we want to ever allow text on the project itself?
      const key = projectStore.getQueueKey(docId, field, change.projectId);
      onRecieveDelta(key, new Delta(op.value));
    });
  }

  function onRecieveDelta(key: string, delta: Delta) {
    if (hasEditors(key)) return; // let the history module transform undo/redos
    if (!histories[key]) return; // we don't have any undo stacks for this doc yet
    transformHistoryStack(histories[key], delta);
  }

  /**
   * Get a new editor for the given doc and field with the provided options. This will be for the currently loaded project
   */
  function getEditor(project: Project, doc: Doc, field: string, options?: EditorOptions) {
    const key = projectStore.getQueueKey(doc.id, field, project.id);
    const types = getTypeset(project, doc, field, options);
    const modules = getModules(project, doc, field, options);
    const enabled = options.enabled === false ? false : true;
    const editor = new Editor({ identifier: { id: doc.id, field, key }, types, modules, dev: !isProduction, enabled });

    if (options.header) {
      editor.setText(doc[field] || '', undefined, Source.api);
    } else {
      // Clean up \r\n in docs before adding the TextDocument to the editor
      let textDocument = (doc[field] as TextDocument) || new TextDocument();
      const text = textDocument.getText();
      if (text.includes('\r')) {
        const delta = new Delta();
        text
          .split('\r')
          .slice(0, -1)
          .forEach(part => delta.retain(part.length).delete(1));
        projectStore.changeText(doc.id, field, delta);
        doc = projectStore.getDoc(doc.id); // get updated doc after change
        textDocument = doc[field];
      }
      editor.set(textDocument, Source.api);
    }

    editorKeys.set(editor, key);
    addEditor(key, editor);

    return editor;
  }

  function returnEditor(editor: Editor) {
    const key = editorKeys.get(editor);
    editorKeys.delete(editor);
    removeEditor(key, editor);
  }

  function getOptions(doc: Doc, field: string, options?: EditorOptions) {
    const docSettings = doc && settings.getFor(doc);
    const customOptions = docSettings && docSettings.editorOptions && docSettings.editorOptions[field];
    return { ...customOptions, ...options };
  }

  function getTypeset(project: Project, doc: Doc, field: string, options: EditorOptions): TypesetTypes {
    let typeset: TypesetTypes;
    if (options.header) {
      return { lines: [options.header] } as TypesetTypes;
    } else if (options.typeset) {
      typeset = options.typeset;
    } else {
      typeset = {
        lines: ['paragraph', 'dlh', 'header', 'list', 'blockquote', 'hr', 'dl', 'image', 'read'],
        formats: ['link', 'comment', 'ins', 'del', 'highlight', 'bold', 'italic', 'underline', 'strike'],
        embeds: ['image', 'br', 'decoration'],
      };
    }

    if (field === 'body') {
      [settings.getFor('dabble'), settings.getFor(project), settings.getFor(doc)].forEach(settings => {
        if (settings.editorTypes) {
          Object.keys(settings.editorTypes).forEach(name => {
            const customTypes = settings.editorTypes[name];
            if (typeof customTypes === 'function') {
              typeset = customTypes(typeset);
            } else {
              for (const [key, types] of Object.entries(customTypes)) {
                types.forEach((type: string) => {
                  if (!typeset[key as keyof TypesetTypes].includes(type)) {
                    typeset[key as keyof TypesetTypes].push(type);
                  }
                });
              }
            }
          });
        }
      });
    }

    return typeset;
  }

  function getModules(project: Project, doc: Doc, field: string, options: EditorOptions) {
    const modules: ModuleInitializers = {
      // rendering: virtualRendering,
      mentionsModule,
      navigation: navigation({ container: '.project-container, .modal-dialog', header: options.header }),
      placeholder: placeholder(
        () => {
          const currentDoc = projectStore.get().docs[doc.id];
          return options.placeholder || (currentDoc && settings.getPlaceholder(currentDoc, field)) || '';
        },
        { keepAttribute: true }
      ),
      pasteHandling,
      smartEntry: smartEntry(),
      smartQuotes,
      spaces,
      links,
      noteLinking,
      findReplace: findReplace(),
      blurModule,
    };

    [settings.getFor('dabble'), settings.getFor(project), settings.getFor(doc)].forEach(settings => {
      if (settings.editorModules) {
        Object.keys(settings.editorModules).forEach(name => {
          const module = settings.editorModules[name](doc, field);
          if (module) modules[name] = module;
        });
      }
    });

    return modules;
  }

  function getHistoryStack(key: string) {
    let stack = histories[key];
    if (!stack) {
      histories[key] = stack = undoStack();
    }
    return stack;
  }

  function addEditor(key: string, editor: Editor) {
    const editorsSet = editors[key] || (editors[key] = new Set());
    editorsSet.add(editor);
  }

  function removeEditor(key: string, editor: Editor) {
    const editorsSet = editors[key];
    if (!editorsSet) return;
    editorsSet.delete(editor);
  }

  function hasEditors(key: string) {
    const editorsSet = editors[key];
    return editorsSet && editorsSet.size > 0;
  }

  return {
    getEditable,
  };
}
