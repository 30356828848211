<script>
  import { shownModal, t } from '@dabble/app';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiTrophy } from '@mdi/js';
  import { nanowrimoTrialTime } from '../nanowrimo';

  $: nanoTime = $nanowrimoTrialTime;

  function openInvite() {
    $shownModal = 'nanowrimoInvite';
  }
</script>

{#if nanoTime}
  <button class="dropdown-item" on:click={openInvite}>
    <Icon path={mdiTrophy} />{$t('nanowrimo_menu')}
  </button>
{/if}
